import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import PatientMedicCardButton from 'patient/screens/medicList/PatientMedicCardButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAuthState } from 'shared/redux/src/StatesGetter';
import { useTranslation } from 'react-i18next';
import ShareOnSocial from 'patient/components/ShareOnSocial';
import Alerts from 'shared/components/Alerts';
import _ from 'lodash';
import * as DOMPurify from 'dompurify';
import { use } from 'i18next';
import { setPendingAppointment } from '../../../shared/redux/actions/PatientAppointmentActions';
import { requestMediaCamera } from '../../../shared/modules/PermissionsUtils';
import Utils from '../../../shared/modules/Utils';

const PatientMedicCard = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const {
    toggleFavorite,
    doctorIsFavorite,
    onScheduleClicked,
    onCallNowClicked,
    listItem,
    classes,
    showAllSpecializations,
  } = props;
  const sanitizer = DOMPurify.sanitize;
  const [hasProfilePicture, setHasProfilePicture] = useState<boolean>(false);
  const getFile = async (fileLink) => {
    if (fileLink && fileLink !== '') {
      const response = await fetch(fileLink);

      if (response.status === 200) {
        setHasProfilePicture(true);
      }
    }
  };

  const { isAvailableForCallNow, specializationId, hasSchedule } = listItem;
  const onSchedule = () => {
    if (!isLoggedIn) {
      dispatch(setPendingAppointment({ type: 'programmed', doctor: listItem, specializationId }));
      Alerts.okCancelAlert(t('info'), t('alerts.youNeedToLogin'), () => {
        localStorage.setItem('CURRENT_LINK', `${window.location.pathname}`);
        navigate('/patient/login');
      });
    }

    let hasControl = false;
    if (
      listItem.doctorServices?.filter((service) => {
        return service.slug === 'control';
      }).length > 0
    ) {
      hasControl = true;
    }
    onScheduleClicked(listItem, specializationId, hasControl);
  };

  const toggleFavoriteItem = () => toggleFavorite(listItem.id);
  const onCall = () => {
    if (!isLoggedIn) {
      dispatch(setPendingAppointment({ type: 'callNow', doctor: listItem, specializationId }));
      Alerts.okCancelAlert(t('info'), t('alerts.youNeedToLogin'), () => {
        localStorage.setItem('CURRENT_LINK', `${window.location.pathname}`);

        navigate('/patient/login');
      });
    }

    if (isAvailableForCallNow) {
      requestMediaCamera().then((response) => {
        if (!response) {
          window.location.href = '/support/permissions';
        } else {
          onCallNowClicked(listItem, specializationId);
        }
      });
    }
  };

  const description =
    listItem?.doctorDescription?.length > 0
      ? Utils.shortenText(listItem?.doctorDescription, 120)
      : '';

  let lastKey: any = '';
  if (listItem.doctorServices) {
    lastKey = listItem.doctorServices.length - 1;
  }

  useEffect(() => {
    getFile(listItem?.imgUrl).then();
  }, [listItem]);

  return (
    <div className={classes}>
      <div className="box">
        <div className={`medic-card-header ${listItem.availability}`}>
          {isLoggedIn && (
            <div className="favorite" onClick={toggleFavoriteItem}>
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon={doctorIsFavorite ? 'Heart_Filled' : 'Heart_Empty'}
              />
            </div>
          )}
          <ShareOnSocial doctor={listItem} profilePage={false} />
        </div>

        <div className="link-box">
          <div className="rating">
            <div className="profile-picture">
              <div
                className="avatar"
                style={
                  hasProfilePicture
                    ? { backgroundImage: `url(${listItem?.imgUrl})` }
                    : { backgroundColor: '#E5E7E8' }
                }
              >
                {!hasProfilePicture && (
                  <div className="image-placeholder">{`${listItem?.firstName?.charAt(
                    0,
                  )}${listItem?.lastName?.charAt(0)}`}</div>
                )}
                <i className={`status ${listItem.availability} `} />
              </div>
            </div>
            <div className="rating-value">
              <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="14" icon="Star" />
              <i className="rating-icon" />
              <span>
                {t('note')} {listItem.averageRating}
              </span>
            </div>
          </div>

          <Link className="medic-info cursor-pointer" to={`/patient/medicProfile/${listItem.id}`}>
            <div className="left-column">
              <div className="name">{listItem.fullNameWithTitle}</div>
              <div className="specialization">
                {!showAllSpecializations
                  ? listItem.specializationName
                  : listItem.doctorSpecializations
                      ?.map((specialization) => specialization.name)
                      .join(', ')}
              </div>

              <div className="clinic">
                <div className="info-clinic">{listItem.organizationClinicName}</div>
                {listItem.organizationLogo && (
                  <div
                    className="avatar"
                    style={{ backgroundImage: `url(${listItem.organizationLogo})` }}
                  />
                )}
              </div>
            </div>
          </Link>
        </div>

        {description && (
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: sanitizer(description) }}
          />
        )}
        <div className="footer-box">
          {listItem.firstAvailableTime && (
            <>
              <div className="d-flex justify-content-between border-top first-available-time">
                <div className="subtitle">{t('appointments.availabilityFrom')}</div>
                <div className="subtitle-value">{listItem.firstAvailableTime}</div>
              </div>
            </>
          )}
          {!showAllSpecializations && (
            <>
              <div className="border-top border-bottom">
                {listItem.doctorServices !== undefined
                  ? listItem.doctorServices
                      .filter((service) => {
                        return service.isActive;
                      })
                      .map((service) => {
                        return (
                          <div
                            key={_.uniqueId()}
                            className={`d-flex justify-content-between prices ${
                              service[0] !== lastKey ? 'border-bottom' : ''
                            }`}
                          >
                            <div className="subtitle">
                              {service.slug === 'consultation'
                                ? Utils.capitalizeFirstLetter(t('appointments.consultationPrice'))
                                : Utils.capitalizeFirstLetter(t('appointments.controlPrice'))}
                            </div>
                            <div className="price">
                              {
                                // @ts-ignore
                                service.priceAsString
                              }{' '}
                              /{' '}
                              {
                                // @ts-ignore
                                service.duration
                              }{' '}
                              {t('minutes')}
                            </div>
                          </div>
                        );
                      })
                  : ''}
              </div>
            </>
          )}

          <div className="action-buttons mt-4">
            {hasSchedule && (
              <PatientMedicCardButton
                id={listItem.rowId}
                onClickEvent={onSchedule}
                listItemSchedule={listItem}
                centerTextButton={['/patient/account'].indexOf(location.pathname) > -1}
              />
            )}
            <PatientMedicCardButton status={listItem.availability} onClickEvent={onCall} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientMedicCard;
